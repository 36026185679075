import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { getABNDetails, getACNDetails } from '../../api/submissionAPI';

const StageOne = (props) => {
    const formik = useFormik({
        validationSchema: Yup.object({
            tradingName: Yup.string().required("Trading Name is required."),
            abn: Yup.string().required("ABN is required.").matches(/^[0-9]+$/, "Please enter a valid ABN.").min(11, "Please enter a valid ABN.").max(11, "Please enter a valid ABN."),
            acn: Yup.string().optional(),
            isTrustCorp: Yup.boolean(),
            trusteeIndividualName: Yup.string().when('isTrustCorp', {
                is: false,
                then: Yup.string().required("Trustee Individual Name is required when the Trustee is not a Corporation.")
            }),
        }),
        initialValues: {
            ...props.formData,
            isTrustCorp: true,
            trusteeIndividualName: '',
        },
        onSubmit: (values) => {
            props.handleNext({
                ...values,
                abnIsTrust: isTrust,
                acn: values.isTrustCorp ? values.acn : '',
            });
        }
    });

    const [isTrust, setIsTrust] = useState(false);
    const [abnEntityName, setAbnEntityName] = useState('')
    const [abnEntityError, setAbnEntityError] = useState('')
    const [acnEntityName, setAcnEntityName] = useState('')
    const [acnEntityError, setAcnEntityError] = useState('')

    const handleIsTrustCorpChange = (e) => {
        const { checked } = e.target;
        formik.setFieldValue('isTrustCorp', checked);
        if (!checked) {
            formik.setFieldValue('acn', '');
            setAcnEntityName('');
            setAcnEntityError('');
        } else {
            formik.setFieldValue('trusteeIndividualName', '');
        }
    };

    const updatedABN = async (e) => {
        // Remove spaces from the ABN
        e.target.value = e.target.value.replace(/\s/g, '');
        formik.handleChange(e);

        const abn = e.target.value.replace(/\s/g, '');

        setAbnEntityName('')

        if (validateABN(abn)) {
            const abnDetails = await getABNDetails(abn)
            if(abnDetails || abnDetails?.err === false) {
                const { EntityTypeName, EntityName, Acn } = abnDetails.data

                setIsTrust(EntityTypeName.includes("Trust"))
                setAbnEntityName(EntityName)
                setAbnEntityError('')

                if(!EntityTypeName.includes("Trust") && Acn !== "") {
                    // Set the ACN if it is not a trust
                    formik.setFieldValue('acn', Acn)
                }
            } else {
                // handle error
                setAbnEntityError('Entity not found. Please check the ABN.')
            }
        } else {
            if (abn.length === 11) {
                setAbnEntityError('Invalid ABN. Please check the ABN.')
            }
        }
    }

    const updatedACN = async (e) => {
        // Remove space from the ACN
        e.target.value = e.target.value.replace(/\s/g, '');
        formik.handleChange(e);

        const acn = e.target.value.replace(/\s/g, '');

        setAcnEntityName('')
        if (acn.length === 9) {
            const acnDetaiils = await getACNDetails(acn)
            if(acnDetaiils || acnDetaiils?.err === false) {
               const { data } = acnDetaiils
               const { result } = data;
               const { records } = result;

                if(records.length > 0) {
                    const firstRecord = records[0]
                    setAcnEntityName(firstRecord['Company Name'])
                    setAcnEntityError('')
                } else {
                    setAcnEntityName('')
                    setAcnEntityError('Entity not found. Please check the ACN.')
                }
            } else {
                setAcnEntityName('')
                setAcnEntityError('Entity not found. Please check the ACN.')
            }
        }
    }


    const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
    const validateABN = (abnRaw) => {
        if (!abnRaw) {
            return false;
        }
    
        // strip non-alphanumeric characters
        const abn = abnRaw.toString().replace(/[^a-z\d]/gi, '');
    
        // check if length is 11 digits
        if (abn.length !== 11) {
        return false;
        }
    
        // apply ato check method
        let sum = 0;
        weights.forEach((weight, position) => {
        const digit = Number(abn[position]) - (position ? 0 : 1);
        sum += weight * digit;
        });
    
        const checksum = sum % 89;
    
        return checksum === 0;
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="step">
                <h2>Supplier Details</h2>
                <div className="stepform">
                    <label>Trading Name</label>

                    <input type="text" name="tradingName" onChange={formik.handleChange} value={formik.values.tradingName} />
                    <label>ABN</label>

                    <input type="text" name="abn" onChange={updatedABN} value={formik.values.abn} />
                    {abnEntityName && <p>Entity found: {abnEntityName}</p>}
                    {abnEntityError && <p>{abnEntityError}</p>}

                    {isTrust && (
                        <div>
                            <p className='acntitle'>Entity Details of Trustee</p>
                            <p>The ABN you have provided is a Trust, please provide details of the Trustee.</p>
                            
                            <div>
                                <label>
                                    <input
                                        type="checkbox"
                                        name="isTrustCorp"
                                        checked={formik.values.isTrustCorp}
                                        onChange={handleIsTrustCorpChange}
                                        className="checkbox"
                                    />
                                    Trustee is a Corporation
                                </label>
                            </div>

                            {formik.values.isTrustCorp ? (
                                <>
                                    <label>ACN</label>
                                    <input type="text" name="acn" onChange={updatedACN} value={formik.values.acn} />
                                    {acnEntityName && <p>Entity found: {acnEntityName}</p>}
                                    {acnEntityError && <p>{acnEntityError}</p>}
                                </>
                            ) : (
                                <>
                                    <label>Name of Individual</label>
                                    <input
                                        type="text"
                                        name="trusteeIndividualName"
                                        onChange={formik.handleChange}
                                        value={formik.values.trusteeIndividualName}
                                    />
                                    {formik.touched.trusteeIndividualName && formik.errors.trusteeIndividualName && (
                                        <p className="error">{formik.errors.trusteeIndividualName}</p>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
                <button
                    className="stepbutton"
                    type="submit"
                    disabled={
                        (!acnEntityName && isTrust && formik.values.isTrustCorp) ||
                        !abnEntityName ||
                        (isTrust && !formik.values.isTrustCorp && !formik.values.trusteeIndividualName)
                    }
                >
                    Next
                </button>
            </div>
        </form>
    );
};

export default StageOne;

import React, { Component } from "react";
import './Submission.scss'
import { fetchSubmission, approveSubmission, requestSubmissionUpdate } from '../api/adminAPI';
import { fetchSubmissionFiles } from '../api/fileAPI';
import { withRouter } from "react-router";
import { ReactComponent as Cross } from '../images/red_cross.svg'
import { ReactComponent as Tick } from '../images/green_tick.svg'
import { Modal, Input, Select} from "antd";

const { TextArea } = Input;

const { Option } = Select;

class Submission extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            abnStatus: {},
            acnStatus: {},
            files: [],
            isRequestPopupOpen: false,
            isAcceptPopupOpen: false,
            changeReason: "",
            jonasVendorCode: "",
            modalErrMsg: "",
            companyCode: "",
            purchaseCategory: "",
            loading: false
        }

        this.toggleRequestPopup = this.toggleRequestPopup.bind(this);
        this.toggleAcceptPopup = this.toggleAcceptPopup.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUpdateRequest = this.handleUpdateRequest.bind(this);
        this.handleApproveSubmission = this.handleApproveSubmission.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handlePurchaseCategoryChange = this.handlePurchaseCategoryChange.bind(this);
    }

    purchaseCategories = { "QL": [
                                {"CodeDescription":"CONSULTANT - CONSULTANT","Id":"d2e0ced8-4fe5-4297-b80b-f39a5b8ffc0e","ID":"d2e0ced8-4fe5-4297-b80b-f39a5b8ffc0e","Code":"CONSULTANT","Description":"CONSULTANT"},
                                {"ID":"3be670c5-0809-4488-8044-a28b1eabe7c5","Description":"Subcontractor/Consultant"},
                                {"ID":"c4744ac7-45f5-4760-a8d6-d18325ae90a9","Description":"Supplier"}],
                          "VI": [
                                {"CodeDescription":"CONSULTANT - CONSULTANT","Id":"e9003a76-be3b-4210-974d-48ee039583a1","ID":"e9003a76-be3b-4210-974d-48ee039583a1","Code":"CONSULTANT","Description":"CONSULTANT"},
                                {"ID":"aa273d28-79fc-4146-8ab8-df6886c8a866","Description":"Subcontractor/Consultant"},
                                {"ID":"3ad4d4b2-fef6-41f4-8dbf-4c15673c0378","Description":"Supplier"}],
                          "TT": [
                                {"CodeDescription":"CONSULTANT - CONSULTANT","Id":"08d81dda-6b64-491e-8dfb-838c0b38ba96","ID":"08d81dda-6b64-491e-8dfb-838c0b38ba96","Code":"CONSULTANT","Description":"CONSULTANT"},
                                {"ID":"01c9afd9-4013-48ec-b230-96a8fd623fd0","Description":"Subcontractor/Consultant"},
                                {"ID":"b2f66657-5d1c-4e42-85d5-1fadefec8bf5","Description":"Supplier"}],
                          "" : []
                        }

    acnStatus = {
        DRGD: "Deregistered",
        EXAD: "External Administration",
        NOAC: "Not Active",
        NRGD: "Not Registered",
        PROV: "Provisional",
        REGD: "Registered",
        SOFF: "Struck Off",
        DISS: "Dissolved",
        DIV3: "Division 3",
        PEND: "Pending",
    }


    // When component mounts fetch all submissions that have a 'PENDING' state and set them to state.
    componentDidMount() {
        this.setState({...this.state, loading: true})
        fetchSubmission(this.props.match.params.submissionID)
            .then(data => {
                let acnStatus = {}
                const records = data.acnStatus?.result?.records || []
                if (records.length > 0) {
                    acnStatus = records[0]
                }

                this.setState({...this.state, loading: false})
                this.setState({ ...this.state, data: data.submission, files: data.files, abnStatus: data.abnStatusString, acnStatus })
            }).catch((err) => console.log(err))
    }

    // Approve the submission and push back to the admin page.
    handleApproveSubmission() {
        if (this.state.jonasVendorCode === '' || this.state.companyCode === '' | this.state.purchaseCategory === '') {
            this.setState({...this.state, modalErrMsg: "Error, please ensure you have entered and selected all data."})
        } else if (this.state.jonasVendorCode.length > 10) {
            this.setState({...this.state, modalErrMsg: "Error, Vendor Code cannot be more than 10 characters."})
        } else {
            this.setState({...this.state, loading: true})
            approveSubmission(this.state.data, this.state.jonasVendorCode, this.state.companyCode, this.state.purchaseCategory)
            .then(data => {
                if (data.success === true) {
                    this.setState({...this.state, loading: false})
                    this.toggleAcceptPopup()
                    this.props.history.push('/admin')
                }
                this.setState({...this.state, modalErrMsg: `There has been an unexpected error: ${data.errorMsg}`, loading: false})
            }).catch((err) => console.log(err))
        }
    }

    // This is called when a user wants to download the requested files for that particular submission.
    // Response from server is a blob and need to create a ObjectURL from that blob.
    fetchSubmissionFile(submissionID, fileID, filename) {
        fetchSubmissionFiles(submissionID, fileID)
            .then((data) => {
                if (data.type === 'application/pdf') {
                    const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}))
                    const pdfWindow = window.open('/');
                    pdfWindow.location.href = url
                } else {
                    const url = window.URL.createObjectURL(new Blob([data]))
                    const link = document.createElement('a')
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link)
                }
            }).catch((err) => console.log(err))
    }


    toggleRequestPopup() {
        this.setState({ ...this.state, isRequestPopupOpen: !this.state.isRequestPopupOpen, changeReason: '', modalErrMsg: ''})
    }

    toggleAcceptPopup() {
        this.setState({ ...this.state, isAcceptPopupOpen: !this.state.isAcceptPopupOpen, jonasVendorCode: '', modalErrMsg: ''})
    }

    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }

    handleUpdateRequest() {
        if (this.state.changeReason === '') {
            this.setState({...this.state, modalErrMsg: "Please provide reasons to request a change."})
        } else {
            requestSubmissionUpdate(this.state.data.id, this.state.changeReason)
            .then(data => {
                if (data.success === true) {
                    this.toggleRequestPopup()
                    this.props.history.push('/admin')
                }
            }).catch((err) => console.log(err));
        }
    }

    handleCompanyChange(value) {
        this.setState({...this.state, companyCode: value}, () => {
            this.handlePurchaseCategoryChange()
        });
    }

    handlePurchaseCategoryChange() {
        this.purchaseCategories[this.state.companyCode].map(category => {
            if (category.Description === this.state.data.creditorType) {
                this.setState({...this.state, purchaseCategory: category.ID})
            }
        })
    }

    render() {
        return (
            <div>
                <Modal title="Request Changes" visible={this.state.isRequestPopupOpen} onOk={this.handleUpdateRequest} onCancel={this.toggleRequestPopup} confirmLoading={this.state.loading}>
                    <p>What are the requested changes? (Only provide the reason)</p>
                    <TextArea name="changeReason" value={this.state.changeReason} onChange={this.handleChange} />
                    <p className="modalerror">{this.state.modalErrMsg}</p>
                </Modal>

                <Modal title="Accept Submission" visible={this.state.isAcceptPopupOpen} onOk={this.handleApproveSubmission} onCancel={this.toggleAcceptPopup} confirmLoading={this.state.loading}>
                    <div className="companySelect">
                        <Select defaultValue="Select Company"  style={{width: '50%' }} onChange={this.handleCompanyChange}>
                            <Option value="VI">VI - Victoria</Option>
                            <Option value="QL">QL - Queensland</Option>
                            <Option value="TT">TT - Testing Company</Option>
                        </Select>
                    </div>
                    

                    <p>Please type a Jonas Vendor Code for this submission: {this.state.data.id}</p>
                    <Input type="text" placeholder="Vendor Code" name="jonasVendorCode" value={this.state.jonasVendorCode} onChange={this.handleChange} />
                    
                    <p className="modalerror">{this.state.modalErrMsg}</p>
                </Modal>
                
            <div className="buttons">
                <button className="return" onClick={() => this.props.history.push('/admin')}>Go Back</button>
                {this.state.data.status !== "APPROVED" &&
                    <div>
                        <button className="request" onClick={this.toggleRequestPopup}>Request Changes</button>
                        <button className="approve" onClick={this.toggleAcceptPopup}>Approve Submission</button>
                    </div> 
                }
            </div>
            <div className="informationContainer">
                <div className="supplierInformation">
                    <h2>Supplier Details</h2>

                    <h3>Trading Name</h3>
                    <p>{this.state.data.tradingName}</p>

                    <h3>Company Name</h3>
                    <p>{this.state.data.companyName}</p>

                    <h3>ABN</h3>
                    <p>{this.state.data.abn}<br/>{this.state.abnStatus.EntityTypeName}</p>

                    <h3>{(this.state.data.isTrust && this.state.data.isTrustCorp) ? 'ACN' : 'Individual'} {this.state.data.isTrust && '(Entity Details of Trustee)'}</h3>
                    {(this.state.data.isTrust && this.state.data.isTrustCorp) ? (
                        <p>AAA{this.state.data.acn || "Not Supplied/Collected"}<br/>{this.state.acnStatus['Company Name'] || ''}</p>
                    ) : (
                        <p>{this.state.data.trusteeIndividualName || "Not Supplied/Collected"}</p>
                    )}


                    {this.state.abnStatus.AbnStatus === "Active" &&
                        <div className="abnInline">
                            <Tick className="greenTick"/>
                            <p>This ABN is active and effective from the {this.state.abnStatus.AbnStatusEffectiveFrom}</p>
                        </div>
                    }
                    {this.state.abnStatus.AbnStatus !== "Active" && 
                        <div className="abnInline">
                            <Cross className="redCross"/>
                            <p>This ABN is not valid/active.</p>
                        </div>
                    }
                    
                    {this.state.abnStatus.Gst ?
                        <div className="abnInline">
                            <Tick className="greenTick"/>
                            <p>This ABN has been registered for GST since {this.state.abnStatus.Gst}</p>
                        </div> 
                    :
                        <div className="abnInline">
                            <Cross className="redCross"/>
                            <p>This ABN is not registered for GST.</p>
                        </div>
                    }

                    {this.state.acnStatus['Company Name'] && 
                        this.state.acnStatus['Status'] === 'REGD' ?
                            <div className="abnInline">
                                <Tick className="greenTick"/>
                                <p>The ACN is currently registered.</p>
                            </div>
                        : <div className="abnInline">
                            <Cross className="redCross"/>
                            <p>This ACN is not registered with ASIC. (Status: {this.acnStatus[this.state.acnStatus['Status']]})</p>
                            </div>}
                    

                    <h3>Supplier Address</h3>
                    <p>{this.state.data.supplierAddress}, {this.state.data.supplierTownSuburb}, {this.state.data.supplierState}, {this.state.data.supplierPostcode}</p>
                </div>
                <div className="accountInformation">
                    <h2>Account Information</h2>

                    <h3>Account Contact Name</h3>
                    <p>{this.state.data.accountContactName}</p>

                    <h3>Account Contact Email</h3>
                    <p>{this.state.data.accountContactEmail}</p>

                    <h3>Account Contact Phone</h3>
                    <p>{this.state.data.accountContactPhone}</p>

                    <h3>Remittance Email</h3>
                    <p>{this.state.data.remittanceEmail}</p>

                    <h3>Purchase Order Email</h3>
                    <p>{this.state.data.purchaseOrderEmail}</p>

                    <h3>Account Address</h3>
                    <p>{this.state.data.accountAddress}, {this.state.data.accountTownSuburb}, {this.state.data.accountState}, {this.state.data.accountPostcode}</p>
                </div>
                <div className="bankInformation">
                    <h2>Bank Information</h2>

                    <h3>Financial Institution</h3>
                    <p>{this.state.data.bankName}</p>

                    <h3>Bank BSB</h3>
                    <p>{this.state.data.bankBSB}</p>

                    <h3>Bank Account Number</h3>
                    <p>{this.state.data.bankAccountNumber}</p>

                    <h3>Bank Account Name</h3>
                    <p>{this.state.data.bankAccountName}</p>

                    <hr />
                    <h2>Uploaded Files</h2>
                    <ul>
                        {this.state.files.map((file) => (
                            <li key={file.id}><button className="downloadButton" onClick={() => this.fetchSubmissionFile(this.state.data.id, file.id, file.filename)}>{file.filename}</button></li>
                        ))}
                    </ul>
                    {this.state.files.length ?
                    <p>Please click on the file you wish to download.</p>
                    : <p>There are no files related to this submission.</p>
                    }
                    
                </div>
            </div>
                
            </div>
        )
    }

}

export default withRouter(Submission);
